<template>

      <v-card>
        <v-card-title class="title font-weight-regular justify-space-between">
          <span>{{ formTitle }}</span>
          <v-btn icon @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <!-- <v-row no-gutters class="pb-4">
          <v-col cols="12" align="center">
            <v-icon
              class="px-1"
              small
              :color="formStep ? 'active_purple' : 'button_blue'"
              >mdi-checkbox-blank-circle</v-icon
            >
            <v-icon
              class="px-1"
              small
              :color="formStep ? 'button_blue' : 'active_purple'"
              >mdi-checkbox-blank-circle</v-icon
            >
          </v-col>
        </v-row> -->

        <v-window v-model="formStep">
          <v-window-item :value="0">
            <v-card-text class="pa-5">
              <v-text-field
                v-model="meetingTitle"
                :label="$t('misc.title')"
                placeholder=""
                filled
                dense
                autocomplete="off"
              />

              <v-textarea
                v-model="meetingDescription"
                :label="$t('misc.description')"
                placeholder=""
                filled
                dense
                autocomplete="off"
              />

              <v-select
                :items="[currentUser]"
                :label="$t('misc.owner')"
                filled
                item-value="id"
                dense
                :value="currentUser.id"
                disabled
              >



              <template v-slot:selection="data">
                {{ data.item.first_name + " " + data.item.last_name}}
              </template>


              </v-select>
            </v-card-text>
          </v-window-item>

          <v-window-item :value="1">
            <v-card-text class="pa-5">
              <v-text-field
                v-model="meetingTitle"
                :label="$t('misc.title')"
                placeholder=""
                filled
                dense
                autocomplete="off"
              />

              <v-textarea
                v-model="meetingDescription"
                :label="$t('misc.description')"
                placeholder=""
                filled
                dense
                autocomplete="off"
              />

              <v-select
                :items="[currentUser]"
                :label="$t('misc.owner')"
                filled
                item-value="id"
                dense
                :value="currentUser.id"
                disabled
              >

              <template v-slot:selection="data">
                {{ data.item.first_name + " " + data.item.last_name}}
              </template>

              </v-select>
            </v-card-text>
          </v-window-item>
        </v-window>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            :disabled="formStep === 0"
            text
            @click="formStep = 0"
            color="almost_black"
          >
            <v-icon class="pr-1" small>mdi-arrow-left-thick</v-icon>
            {{ $t("misc.back") }}
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            v-if="formStep === 0"
            color="button_blue"
            class="white--text"
            depressed
            @click="formStep++"
          >
            {{ $t("misc.next") }}
            <v-icon class="pl-1" small>mdi-arrow-right-thick</v-icon>
          </v-btn>

          <v-btn
            v-if="formStep === 1"
            color="success"
            class="white--text"
            depressed
            @click="createMeeting()"
          >
            {{ $t("meetings.createMeeting") }}
          </v-btn>
        </v-card-actions>
      </v-card>

</template>

<script>
import userAvatar from "../shared/userAvatar";

export default {
  name: "newMeeting",
  mixins: [],
  components: {userAvatar},
  props: {
    feed_start_at: {
      type: String,
      required: false,
      default: ""
    }

  },
  data() {
    return {
      meetingTitle: "",
      meetingDescription: "",
      meetingStartTime: this.feed_start_at ? this.feed_start_at : Date.now().toString(),
      formStep: 0,
    };
  },
  computed: {
    formTitle() {
      switch (this.formStep) {
        case 0:
          return this.$t("meetings.meetingInfos");
        case 1:
          return this.$t("meetings.participants");
      }
    },
  },
  methods: {
    createMeeting() {
      alert("Clicked on create meeting");
    },
  },
  mounted() {},
  beforeDestroy() {},
};
</script>

